import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import router from "../../router";
import ApiService from "@/core/services/ApiService";

@Module
export default class AuthModule extends VuexModule {
  errors = {};
  user = {};
  isAuthenticated = !!JwtService.getToken();
  redirectUrl = localStorage.getItem("auth.redirect_url");
  bitrix24AccessToken = localStorage.getItem("auth.bitrix24AccessToken");
  bitrix24AccessTokenExpires = Number(
    localStorage.getItem("auth.bitrix24AccessTokenExpires")
  );
  bitrix24RefreshToken = localStorage.getItem("auth.bitrix24RefreshToken");

  /**
   * Get current user object
   * @returns User
   */
  get currentUser() {
    return this.user;
  }

  get getRedirectUrl() {
    return this.redirectUrl;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  get getBitrix24AccessToken() {
    return this.bitrix24AccessToken;
  }

  get getBitrix24AccessTokenExpires() {
    return this.bitrix24AccessTokenExpires;
  }

  get getBitrix24RefreshToken() {
    return this.bitrix24RefreshToken;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](payload) {
    this.isAuthenticated = true;
    this.user = payload.user;
    this.errors = {};
    JwtService.saveToken(payload.jwtToken);
  }

  @Mutation
  setRedirectUrl(url) {
    this.redirectUrl = url;
    localStorage.setItem("auth.redirect_url", url);
  }

  @Mutation
  setBitrix24AccessToken(accessToken) {
    this.bitrix24AccessToken = accessToken;
    localStorage.setItem("auth.bitrix24AccessToken", accessToken);
  }

  @Mutation
  setBitrix24AccessTokenExpires(accessTokenExpires) {
    this.bitrix24AccessTokenExpires = accessTokenExpires;
    localStorage.setItem("auth.bitrix24AccessTokenExpires", accessTokenExpires);
  }

  @Mutation
  setBitrix24RefreshToken(refreshToken) {
    this.bitrix24RefreshToken = refreshToken;
    localStorage.setItem("auth.bitrix24RefreshToken", refreshToken);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {};
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  async [Actions.VERIFY_AUTH]() {
    const now = Date.now();
    if (this.context.getters["getBitrix24AccessTokenExpires"] < now / 1000) {
      if (this.context.getters["getBitrix24RefreshToken"] !== null) {
        const response = await ApiService.query(
          "connect/bitrix24/refresh-token",
          {
            params: {
              refresh_token: this.context.getters["getBitrix24RefreshToken"],
            },
          }
        );

        this.context.commit(
          "setBitrix24AccessToken",
          response.data.access_token
        );
        this.context.commit(
          "setBitrix24AccessTokenExpires",
          response.data.expires
        );
        this.context.commit(
          "setBitrix24RefreshToken",
          response.data.refresh_token
        );

        const userResponse = await ApiService.query("connect/bitrix24/user", {
          params: { access_token: response.data.access_token },
        });

        this.context.commit("setAuth", userResponse.data);
      }

      router.push({ name: "sign-in" });
    }
  }
}
