import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import CountryModule from "@/store/modules/CountryModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    CountryModule,
    domain: {
      namespaced: true,
      state: () => ({
        domain: null,
        bitrixBaseUrl: null,
        propositionBaseUrl: null,
        color: null,
        colorText: null,
        vehiclesIndex: null,
        firstImage: null,
        secondImage: null,
        societe: null,
      }),
      getters: {
        getDomain(state) {
          return state.domain;
        },

        bitrixDomain(state) {
          switch (state.domain) {
            case "egesparc.jugandautos.com":
              return "intranet.jugandautos.com/oauth";
            case "egesparc.balleydier4x4.com":
              return "intranet.balleydier4x4.com/oauth";
            default:
              return "intranet.balleydier4x4.com/oauth";
            // return "intranet.jugandautos.com/oauth";
          }
        },

        colorDomain(state) {
          switch (state.domain) {
            case "egesparc.jugandautos.com":
              return "vermilion";
            case "egesparc.balleydier4x4.com":
              return "lightning-yellow";
            default:
              return "lightning-yellow";
            // return "vermilion";
          }
        },

        bitrixClientId(state) {
          switch (state.domain) {
            case "egesparc.jugandautos.com":
              return "local.6685710820eca6.45393814";
            case "egesparc.balleydier4x4.com":
              return "local.65733a9da3e5a0.16757212";
            default:
              // return "local.654a06a65d9b26.45744294"; // JA
              return "local.65733ad4692e66.08237544"; // B4X4
          }
        },

        bitrixRedirectUri(state) {
          switch (state.domain) {
            case "egesparc.jugandautos.com":
              return "https://egesparc.jugandautos.com/#/sign-in";
            case "egesparc.balleydier4x4.com":
              return "https://egesparc.balleydier4x4.com/#/sign-in";
            default:
              // return "https://egesparc.jugandautos.com/#/sign-in"; // JA
              return "https://egesparc.balleydier4x4.com/#/sign-in"; // B4X4
          }
        },
      },
      mutations: {
        setDomain(state, domain) {
          state.domain = domain;

          // state.domain = "egesparc.balleydier4x4.com";
          // state.domain = "egesparc.jugandautos.com";

          switch (state.domain) {
            case "egesparc.jugandautos.com":
              state.color = "vermilion";
              state.colorText = "black";
              state.vehiclesIndex = "gesparc_jugand_autos_vehicules";
              state.societe = "JUGAND AUTOS";
              state.firstImage = 22;
              state.secondImage = 21;
              state.bitrixBaseUrl = "https://intranet.jugandautos.com";
              state.propositionBaseUrl = "https://www.jugandautos.com";
              break;
            case "egesparc.balleydier4x4.com":
              state.color = "lightning-yellow";
              state.colorText = "black";
              state.vehiclesIndex = "gesparc_balleydier_4x4_vehicules";
              state.societe = "BALLEYDIER 4X4";
              state.firstImage = 24;
              state.secondImage = 21;
              state.bitrixBaseUrl = "https://intranet.balleydier4x4.com";
              state.propositionBaseUrl = "https://doc.balleydier4x4.com";
              break;
            default:
              // state.color = "lightning-yellow";
              // state.colorText = "black";
              // state.vehiclesIndex = "gesparc_balleydier_4x4_vehicules";
              // state.firstImage = 24;
              // state.secondImage = 21;
              state.color = "vermilion";
              state.colorText = "white";
              state.vehiclesIndex = "gesparc_jugand_autos_vehicules";
              state.societe = "BALLEYDIER 4X4";
              state.firstImage = 0;
              state.secondImage = 1;
              state.bitrixBaseUrl = "https://intranet.balleydier4x4.com";
              state.propositionBaseUrl = "https://doc.balleydier4x4.com";
              break;
          }
        },
      },
    },
  },
});

export default store;
