import { Module, VuexModule, Mutation } from "vuex-module-decorators";
import { Mutations } from "@/store/enums/StoreEnums";
import { RouteLocationNormalized } from "vue-router";

export interface StoreInfo {
  country: {
    name: string;
    flag: string;
  };
  redirectRoute: RouteLocationNormalized | null;
}

@Module
export default class CountryModule extends VuexModule implements StoreInfo {
  country = {
    name: "France",
    flag: "/media/flags/france.svg",
  };
  redirectRoute = null;

  get currentCountry() {
    return this.country;
  }

  get getRedirectRoute() {
    return this.redirectRoute;
  }

  @Mutation
  [Mutations.SET_COUNTRY](payload: { name: string; flag: string }) {
    this.country = payload;
    localStorage.setItem("country", JSON.stringify(payload));
  }

  @Mutation
  [Mutations.SAVE_REDIRECT_ROUTES](payload) {
    this.redirectRoute = payload;
  }
}
