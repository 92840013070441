const ACCESS_TOKEN = "id_token" as string;
const TOKEN_EXPIRES_AT = "id_expire_at" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ACCESS_TOKEN);
};

export const getExpiresAt = (): string | null => {
  return window.localStorage.getItem(TOKEN_EXPIRES_AT);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ACCESS_TOKEN, token);
};

export const saveExpiresAt = (expireAt: string): void => {
  window.localStorage.setItem(TOKEN_EXPIRES_AT, expireAt);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ACCESS_TOKEN);
  window.localStorage.removeItem(TOKEN_EXPIRES_AT);
};

export default {
  getToken,
  saveToken,
  saveExpiresAt,
  getExpiresAt,
  destroyToken,
};
