import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/404",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/propositions/:id/editer",
        name: "app-proposals-edit",
        component: () =>
          import("@/views/apps/subscriptions/EditSubscription.vue"),
      },
      {
        path: "/propositions/:id/fiche-preparation-vehicule",
        name: "app-proposals-fiche-preparation-vehicule",
        component: () =>
          import(
            "@/views/apps/subscriptions/PropositionFichePreparationVehiculePage.vue"
          ),
      },
      {
        path: "/catalogue",
        name: "app-catalogue-liste",
        component: () =>
          import("@/views/apps/catalogue/CatalogueListePage.vue"),
      },
      {
        path: "/catalogue/ajouter",
        name: "app-catalogue-ajouter",
        component: () =>
          import("@/views/apps/catalogue/CatalogueCreatePage.vue"),
      },
      {
        path: "/catalogue/:id/editer",
        name: "app-catalogue-editer",
        component: () => import("@/views/apps/catalogue/CatalogueEditPage.vue"),
      },
      {
        path: "/commandes",
        name: "app-commandes-liste",
        component: () =>
          import("@/views/apps/commandes/CommandesListePage.vue"),
      },
      {
        path: "/vehicules",
        name: "app-vehicules-liste",
        component: () =>
          import("@/views/apps/vehicules/VehiculesListePage.vue"),
      },
      {
        path: "/vehicules/ajouter",
        name: "app-vehicules-ajouter",
        component: () =>
          import("@/views/apps/vehicules/VehiculesCreatePage.vue"),
      },
      {
        path: "/vehicules/:id/editer",
        name: "app-vehicules-edit",
        component: () => import("@/views/apps/vehicules/VehiculesEditPage.vue"),
      },
      {
        path: "/vehicules/:id",
        name: "app-vehicules-details",
        component: () =>
          import("@/views/apps/vehicules/VehiculesDetailsPage.vue"),
      },
      {
        path: "/changelog",
        name: "app-changelog",
        component: () => import("@/views/Changelog.vue"),
      },
    ],
  },
  {
    path: "/vehicules/:id/affiche",
    name: "app-vehicules-affiche",
    component: () => import("@/views/apps/vehicules/VehiculesAffichePage.vue"),
  },
  {
    path: "/vehicules/:id/fiche-reception",
    name: "app-vehicules-fiche-reception",
    component: () =>
      import("@/views/apps/vehicules/VehiculesFicheReceptionPage.vue"),
  },
  {
    path: "/vehicules/:id/fiche-clef",
    name: "app-vehicules-fiche-clef",
    component: () =>
      import("@/views/apps/vehicules/VehiculesFicheClefPage.vue"),
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("@/views/crafted/authentication/SignIn.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            el: to.hash,
            behavior: "smooth",
          });
        }, 2000);
      });
    }
  },
});

// router.beforeEach((to, from) => {
//   if (to.name !== "sign-in") {
//     store.commit("setRedirectUrl", to.fullPath);
//     store.dispatch(Actions.VERIFY_AUTH);
//   }
//
//   store.commit(Mutations.RESET_LAYOUT_CONFIG);
//
//   setTimeout(() => {
//     window.scrollTo(0, 0);
//   }, 100);
// });

export default router;
