import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, Suspense as _Suspense, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_metainfo, null, {
      title: _withCtx(({ content }) => [
        _createTextVNode(_toDisplayString(content ? `${content} | eGesparc` : `eGesparc`), 1)
      ]),
      _: 1
    }),
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_component_router_view, {
          key: _ctx.$route.fullPath
        }))
      ]),
      _: 1
    }))
  ], 64))
}